body {
  background-color: #070E17 !important;
  color: #ffffff !important;
}

.examples {
  column-gap: 16px;
  column-count: 1;
  @media (min-width: 500px) { column-count: 2; }
  @media (min-width: 1000px) { column-count: 3; }
  @media (min-width: 1500px) { column-count: 4; }
  @media (min-width: 2000px) { column-count: 5; }
}

.examples {
  animation: fadein 0s;
  opacity: 1;
}

.examples > div {
  padding-bottom: 16px;
}

.examples > div > div {
  background-color: #ffffff;
}

.examples > div > div > img:hover {
  opacity: 0.7;
  cursor: pointer;
}

.processingimg {
  animation: fadein2 1s;
  opacity: 0.2;
}

.opacity0 {
  opacity: 0;
}

.fadeoutin {
  opacity: 1;
  animation: fadeoutin 2s;
}
@keyframes fadeoutin {
  0%    { opacity: 1; }
  50%   { opacity: 0; }
  100%  { opacity: 1; }
}

.fadeout {
  animation: fadeout 0.2s;
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

.fadein {
  animation: fadein 2s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein2 {
  from { opacity: 0; }
  to   { opacity: 0.2; }
}


.loadercontainer {
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
}
.loader {
  margin: auto;
  width: 200px;
  height: 200px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  margin: auto;
  content: '';  
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 8px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 4s linear infinite;
  opacity: 0;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}



.loader2 {
  width: 100%;
  height: 22px;
  border-radius: 20px;
  color: #dddddd;
  border: 2px solid;
  position: relative;
}
.loader2::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 0% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 30s;
  animation-timing-function: linear;
}
@keyframes l6 {
  0% {inset:0 100.0% 0 0}
  1% {inset:0 96.059601% 0 0}
  2% {inset:0 92.236816% 0 0}
  3% {inset:0 88.529281% 0 0}
  4% {inset:0 84.934656% 0 0}
  5% {inset:0 81.450625% 0 0}
  6% {inset:0 78.074896% 0 0}
  7% {inset:0 74.805201% 0 0}
  8% {inset:0 71.639296% 0 0}
  9% {inset:0 68.574961% 0 0}
  10% {inset:0 65.61% 0 0}
  11% {inset:0 62.742241% 0 0}
  12% {inset:0 59.969536% 0 0}
  13% {inset:0 57.289761% 0 0}
  14% {inset:0 54.700816% 0 0}
  15% {inset:0 52.200625% 0 0}
  16% {inset:0 49.787136% 0 0}
  17% {inset:0 47.458321% 0 0}
  18% {inset:0 45.212176% 0 0}
  19% {inset:0 43.046721% 0 0}
  20% {inset:0 40.96% 0 0}
  21% {inset:0 38.950081% 0 0}
  22% {inset:0 37.015056% 0 0}
  23% {inset:0 35.153041% 0 0}
  24% {inset:0 33.362176% 0 0}
  25% {inset:0 31.640625% 0 0}
  26% {inset:0 29.986576% 0 0}
  27% {inset:0 28.398241% 0 0}
  28% {inset:0 26.873856% 0 0}
  29% {inset:0 25.411681% 0 0}
  30% {inset:0 24.01% 0 0}
  31% {inset:0 22.667121% 0 0}
  32% {inset:0 21.381376% 0 0}
  33% {inset:0 20.151121% 0 0}
  34% {inset:0 18.974736% 0 0}
  35% {inset:0 17.850625% 0 0}
  36% {inset:0 16.777216% 0 0}
  37% {inset:0 15.752961% 0 0}
  38% {inset:0 14.776336% 0 0}
  39% {inset:0 13.845841% 0 0}
  40% {inset:0 12.96% 0 0}
  41% {inset:0 12.117361% 0 0}
  42% {inset:0 11.316496% 0 0}
  43% {inset:0 10.556001% 0 0}
  44% {inset:0 9.834496% 0 0}
  45% {inset:0 9.150625% 0 0}
  46% {inset:0 8.503056% 0 0}
  47% {inset:0 7.890481% 0 0}
  48% {inset:0 7.311616% 0 0}
  49% {inset:0 6.765201% 0 0}
  50% {inset:0 6.25% 0 0}
  51% {inset:0 5.764801% 0 0}
  52% {inset:0 5.308416% 0 0}
  53% {inset:0 4.879681% 0 0}
  54% {inset:0 4.477456% 0 0}
  55% {inset:0 4.100625% 0 0}
  56% {inset:0 3.748096% 0 0}
  57% {inset:0 3.418801% 0 0}
  58% {inset:0 3.111696% 0 0}
  59% {inset:0 2.825761% 0 0}
  60% {inset:0 2.56% 0 0}
  61% {inset:0 2.313441% 0 0}
  62% {inset:0 2.085136% 0 0}
  63% {inset:0 1.874161% 0 0}
  64% {inset:0 1.679616% 0 0}
  65% {inset:0 1.500625% 0 0}
  66% {inset:0 1.336336% 0 0}
  67% {inset:0 1.185921% 0 0}
  68% {inset:0 1.048576% 0 0}
  69% {inset:0 0.923521% 0 0}
  70% {inset:0 0.81% 0 0}
  71% {inset:0 0.707281% 0 0}
  72% {inset:0 0.614656% 0 0}
  73% {inset:0 0.531441% 0 0}
  74% {inset:0 0.456976% 0 0}
  75% {inset:0 0.390625% 0 0}
  76% {inset:0 0.331776% 0 0}
  77% {inset:0 0.279841% 0 0}
  78% {inset:0 0.234256% 0 0}
  79% {inset:0 0.194481% 0 0}
  80% {inset:0 0.16% 0 0}
  81% {inset:0 0.130321% 0 0}
  82% {inset:0 0.104976% 0 0}
  83% {inset:0 0.083521% 0 0}
  84% {inset:0 0.065536% 0 0}
  85% {inset:0 0.050625% 0 0}
  86% {inset:0 0.038416% 0 0}
  87% {inset:0 0.028561% 0 0}
  88% {inset:0 0.020736% 0 0}
  89% {inset:0 0.014641% 0 0}
  90% {inset:0 0.01% 0 0}
  91% {inset:0 0.006561% 0 0}
  92% {inset:0 0.004096% 0 0}
  93% {inset:0 0.002401% 0 0}
  94% {inset:0 0.001296% 0 0}
  95% {inset:0 0.000625% 0 0}
  96% {inset:0 0.000256% 0 0}
  97% {inset:0 0.0002% 0 0}
  98% {inset:0 0.0001% 0 0}
  99% {inset:0 0.0001% 0 0}
  100% {inset:0 0% 0 0}
}